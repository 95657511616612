<template>

<div class="page-wrapper chiller-theme toggled">
  <a id="show-sidebar" class="btn btn-sm btn-dark" href="#"  v-show="is_login">
    <i class="fas fa-bars"></i>
  </a>
  <nav id="sidebar" class="sidebar-wrapper" v-show="is_login">
    <div class="sidebar-content">
      <div class="sidebar-brand">
        <a href="#">AM
   <span class="font-weight-lighter">   B.Manager</span></a>
        <div id="close-sidebar">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div class="sidebar-header">
        

        <div class="user-pic">
                <i class="fa fa-user-tie  fa-2x colorUser"></i>
        </div>

        <div class="user-info user_name_with">
          <span class="mt-1 user-name colorUser">{{user_name}}</span>
        </div>

        <div class="user-info">
                   <b-button v-if="d_is_chang_pass==true" pill size="sm"  class="text-secondary" variant="link" @click="edit_click_pass">
                      <i class="fa fa-key colorUser"></i>
                  </b-button>
        </div>


      </div>
      <!-- sidebar-header  -->

      <!-- sidebar-search  -->
      <div class="sidebar-menu">
        <ul>
          <li class="header-menu">
            <span class="btn " @click="showDashBord">Tableau De Bord</span>
          </li>

          <li class="header-menu">
            <span>Menu</span>
          </li>




          <li  class="sidebar-dropdown">
            <a v-if="d_par_stock==true" href="#">
              <i class="var_color_stock fa fa-boxes fa-1x"></i>
              <span> Stock</span>

            </a>
            <div class="sidebar-submenu">
              <ul>
                <li v-if="d_gst_depo==true || d_gst_cat==true ||d_gst_art==true ||d_gst_barcode==true">
                  <router-link to="/menuStock"> Dépôts/ Articles</router-link>

           
                </li>

                <li v-if="d_gst_deplac==true">
            
                 <router-link to="/menuDeplac"> Bons De Déplacement</router-link>
                </li>


                 <li v-if="d_gst_entre==true"> 
                 <router-link to="/menuEntrees"> Bon D'Entrées</router-link>
                </li>

                <li v-if="d_gst_sorti==true"> 
                 <router-link to="/menuSorties"> Bon De Sorties</router-link>
                </li>

              </ul>
            </div>
          </li>


          <li class="sidebar-dropdown">
            <a v-if="d_par_achat==true"  href="#">
              <i class="var_color_achat fa fa-cart-arrow-down fa-1x"></i>
              <span> Achats</span>
            </a>
            <div class="sidebar-submenu">
              <ul>
                <li  v-if="d_gst_fournis==true"> 
                  <router-link to="/FournisseursList"> Fournisseurs</router-link>
                </li>

                <li  v-if="d_gst_cmd==true">
                  <router-link to="/menuCmd"> Bon De Commande</router-link>
                </li>

                <li  v-if="d_gst_livrais==true">
                   <router-link to="/menuLivraison"> Bon De Livraison</router-link>
                </li>

                <li  v-if="d_gst_avoir==true">
                   <router-link to="/menuAvoirFour"> Bon D'Avoir</router-link>
                </li>
              </ul>
            </div>
          </li>


          <li  class="sidebar-dropdown">
            <a v-if="d_par_vent==true" href="#">
              <i class="var_color_vente fa fa-cart-plus fa-1x"></i>
              <span> Ventes</span>

            </a>
            <div class="sidebar-submenu">
              <ul>

                <li v-if="d_gst_client_zon==true">
                 <router-link to="/menuClients"> Clients</router-link>
                </li>


                <li v-if="d_gst_devis==true">
                  <router-link to="/menuDevis"> Devis</router-link>
                </li>


                <li v-if="d_gst_bl_client==true">
                  <router-link to="/menuBlClient"> Bon De Livraison</router-link>
                </li>


                <li v-if="d_gst_br_client==true">
                  <router-link to="/menuBnRetour"> Bon De Retour</router-link>
                </li>

                <li v-if="d_gst_fact==true">
                  <router-link to="/menuFacture"> Facture Client</router-link>
                </li>

              </ul>
            </div>
          </li>


          <li  class="sidebar-dropdown">
            <a v-if="d_par_pos==true" href="#">
              <i class="var_color_pos fa fa-cash-register fa-1x"></i>
              <span> POS</span>
            </a>
            <div class="sidebar-submenu">
              <ul>
              
                
                 <li v-if="d_gst_caisse_pos==true">
                <router-link to="/posCaisse">Gestion Des Caisses</router-link>
                </li>
 
                 <li v-if="d_gst_art_cat_pos==true">
                <router-link to="/posCategrArticle">Articles et Catégories</router-link>
                </li>
 
                <li v-if="d_gst_point_vent==true">
                <router-link to="/posMenu">Points De Vente</router-link>
                </li>

              </ul>
            </div>
          </li>



          <li  class="sidebar-dropdown">
            <a v-if="d_par_charg==true" href="#">
              <i class="text-info fa fa-truck-moving fa-1x"></i>
              <span> Distribution</span>
            </a>
            <div class="sidebar-submenu">
              <ul>
                <li v-if="d_gst_vendr==true">
                 <router-link to="/menuClientVend"> Vendeurs / Suivis</router-link>
                </li>
                
                <li v-if="d_gst_prim==true">
                   <router-link to="/menuPrimeClient"> Primes Clients</router-link>
                </li>

                <li v-if="d_gst_cam_boit==true">
                   <router-link to="/menuCamion"> Camions / Boites</router-link>
                </li>


                
                <li v-if="d_gst_charg==true">
                   <router-link to="/menuCharg"> Bon De Charge</router-link>
                </li>


              </ul>
            </div>
          </li>



          <li  class="sidebar-dropdown">
            <a v-if="d_par_financ==true" href="#">
              <i class="var_color_financ fa fa-coins fa-1x"></i>
              <span>  Finance</span>

            </a>
            <div class="sidebar-submenu">
              <ul>

                <li>
                  <router-link to="/banques"> Banques</router-link>
                </li>


                <li v-if="d_gst_tresor==true">
                  <router-link to="/menuTresorerie"> Trésorerie</router-link>
                </li>

                <li v-if="d_gst_depens==true"> 
                  <router-link to="/depenseList"> Dépenses</router-link>
                </li>

                <li v-if="d_gst_paiem_four==true"> 
                  <router-link to="/paiementFour"> Paiements Fournisseurs</router-link>
                </li>

                <li v-if="d_gst_paim_garag==true"> 
                  <router-link to="/paiementGarage"> Paiements Garages</router-link>
                </li>

                <li v-if="d_gst_paim_client==true"> 
                  <router-link to="/acceuilPaiemClient"> Paiements Clients</router-link>
                </li>

              </ul>
            </div>
          </li>




          <li  class="sidebar-dropdown">
            <a v-if="d_par_rh==true" href="#">
              <i class="var_color_rh fa fa-user-tie fa-1x"></i>
              <span> Ressources Humaines</span>
            </a>
            <div class="sidebar-submenu">
              <ul>
              
                
                 <li  v-if="d_gst_depart_employ==true">
                <router-link to="/departementsList">Départements</router-link>
                </li>
 
                 <li  v-if="d_gst_depart_employ==true">
                <router-link to="/employesList">Employés</router-link>
                </li>
 
                <li  v-if="d_gst_absencerh==true">
                <router-link to="/absencesList">Absences</router-link>
                </li>

                <li  v-if="d_gst_congesrh==true">
                <router-link to="/congesList">Congés</router-link>
                </li>


                <li  v-if="d_gst_feuill_temp==true">
                <router-link to="/feuilleTemps">Feuille Du Temps</router-link>
                </li>

                <li  v-if="d_gst_trait_salaire==true">
                <router-link to="/traitementSalaire">Traitement Des Salaires</router-link>
                </li>


                <li  v-if="d_gst_activ_qr==true">
                <router-link to="/qrActivation">App Mobile</router-link>
                </li>
                

              </ul>
            </div>
          </li>


           <li  class="sidebar-dropdown">
            <a v-if="d_par_prod==true" href="#">
              <i class="var_color_prod fa fa-cogs fa-1x"></i>
              <span> Production</span>
            </a>
            <div class="sidebar-submenu">
              <ul>
                            
                 <li  v-if="d_gst_prod_proces==true">
                <router-link to="/menuProcessus"> Processus</router-link>
                </li>

                <li  v-if="d_gst_prod_recep==true">
                <router-link to="/menuReception"> Réceptions / Sorties</router-link>
                </li>  

                <li  v-if="d_gst_prod_prod==true">
                <router-link to="/menuProduction"> Productions</router-link>
                </li>

              </ul>
            </div>
          </li>



           <li  class="sidebar-dropdown">
            <a v-if="d_par_projet==true" href="#">
              <i class="var_color_projet fa fa-project-diagram fa-1x"></i>
              <span> Gestion Des Projets</span>
            </a>
            <div class="sidebar-submenu">
              <ul>
                            
                 <li  >
                <router-link to="/menuProjet"> Projets</router-link>
                </li>

                <li  v-if="d_gst_proj_art==true">
                <router-link to="/projetArticle"> Gestion Des Articles</router-link>
                </li>  

              </ul>
            </div>
          </li>







          <li  class="sidebar-dropdown">
            <a v-if="d_par_rapp==true" href="#">
              <i class="text-light fa fa-chart-bar fa-1x"></i>
              <span> Rapports et Statistiques</span>
            </a>
            <div class="sidebar-submenu">
              <ul>
                

                <li v-show="d_par_stock">
                 <router-link to="/stockRappMenu"> Stock</router-link>
                </li>

       


                <li v-show="d_par_achat">
                 <router-link to="/achatRappMenu"> Achats</router-link>
                </li>


                <li v-show="d_par_vent">
                 <router-link to="/ventesClientsRappMenu"> Ventes</router-link>
                </li>

                <li v-show="d_par_pos">
                 <router-link to="/menuRappPos"> Points De Vente (POS)</router-link>
                </li>


                <li v-show="d_par_financ">
                 <router-link to="/financeRappMenu"> Finance</router-link>
                </li>

                <li v-show="d_gst_charg_vent">
                 <router-link to="/ventesRetourMenu"> Distribution</router-link>
                </li>


                <li v-show="d_gst_cam_boit">
                 <router-link to="/camionBoiteRapMenu"> Camions /Boites</router-link>
                </li>

                <li v-show="d_par_rh">
                 <router-link to="/rhRappMenu"> Resources Humainnes</router-link>
                </li>


                <li v-show="d_par_prod">
                 <router-link to="/rappStatProduction"> Production</router-link>
                </li>

                <li v-show="d_par_projet && d_gst_proj_cout_prix">
                 <router-link to="/menuProjetsRapp"> Gestion Des Projets</router-link>
                </li>


              </ul>
            </div>
          </li>

          <li  class="sidebar-dropdown">
            <a v-if="d_par_param==true" href="#">
              <i class="fa fa-cogs fa-1x"></i>
              <span> Paramètres</span>
            </a>
            <div class="sidebar-submenu">
              <ul>
              
                
                 <li>
                <router-link to="/usersListe">Utilisateurs</router-link>
                </li>
 
                 <li>
                <router-link to="/parametresMenu">Paramètres</router-link>
                </li>

              </ul>
            </div>
          </li>

        </ul>
      </div>
      <!-- sidebar-menu  -->
    </div>
    <!-- sidebar-content  -->

  </nav>
  <!-- sidebar-wrapper  -->
  <main class="page-content  h-100 overflow-auto">


    <div class="container-fluid h-100 overflow-auto">

          <router-view/>


    </div>
  </main>
  <!-- page-content" -->




      <!-- le modal -->
    <b-modal id="passModal" title='Modifier le mot de passe' size="lg" hide-footer>
           <form @submit.prevent="handleSubmitpass">

              <div class=" d-flex justify-content-center text-info mb-4">
              <h3 class="font-weight-lighter">{{user_name}}</h3>
              </div>

               <div class="row">
                        <div class="form-group col">
                            <label for="password">Mot De Passe :</label>
                            <input type="password" v-model="itemForm.password" id="password" name="password" class="form-control"
                                :class="{ 'is-invalid': isSubmitted && $v.itemForm.password.$error }" />
                            <div v-if="isSubmitted && !$v.itemForm.password.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        </div>

                        <div class="form-group col">
                            <label for="password_confirmation">Confirmation Mot De Passe :</label>
                            <input type="password" v-model="itemForm.password_confirmation" id="password_confirmation" name="password_confirmation" class="form-control"
                                :class="{ 'is-invalid': isSubmitted && $v.itemForm.password_confirmation.$error }" />
                            <div v-if="isSubmitted && !$v.itemForm.password_confirmation.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                            <div v-if="isSubmitted && !$v.itemForm.password_confirmation.sameAsPassword"  class="invalid-feedback">Mot De Passe doit être identique !!</div>
                        </div>
               </div>



            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Modifier</button>
            </div>


        </form>
    </b-modal>


      <!-- les trucs public -->
    <FlashMessage :position="'right bottom'"></FlashMessage>



</div>
</template>
<style scoped>
.user_name_with {
	width: 120px;
}

.var_color_stock{
  color: #35b5e4;
}

.var_color_achat{
  color: #c98a8c;
}

.var_color_vente{
  color: #2faaa5;
}
.var_color_projet{
  color: #2faaa5;
}
.var_color_financ{
  color: #926e99;
}
.var_color_rh{
  color: #f796f7;
}
.var_color_prod{
  color: #a798fc;
}
.var_color_pos{
  color: #ebc58d;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
body {
  font-size: 0.9rem;
}
.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*----------------page-wrapper----------------*/

.page-wrapper {
  height: 100vh;
}

.page-wrapper .theme {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 4px;
  margin: 2px;
}

.page-wrapper .theme.chiller-theme {
  background: #1e2229;
}

/*----------------toggeled sidebar----------------*/

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}

@media screen and (min-width: 768px) {
  .page-wrapper.toggled .page-content {
    padding: 0px;
    padding-left: 250px;

  }
}
/*----------------show sidebar button----------------*/
#show-sidebar {
  position: fixed;
  left: 0;
  top: 10px;
  border-radius: 0 4px 4px 0px;
  width: 35px;
  transition-delay: 0.3s;
}
.page-wrapper.toggled #show-sidebar {
  left: -40px;
}
/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
  width: 260px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 999;
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-wrapper a {
  text-decoration: none;
}

/*----------------sidebar-content----------------*/

.sidebar-content {
  max-height: calc(100% - 30px);
  height: calc(100% - 30px);
  overflow-y: auto;
  position: relative;
}

.sidebar-content.desktop {
  overflow-y: hidden;
}

/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.sidebar-wrapper .sidebar-brand > a {

  font-weight: bold;
  flex-grow: 1;
  font-size: 20px;
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
}
/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
  padding: 20px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic {
  float: left;
  width: 40px;
  padding: 2px;
  border-radius: 12px;
  margin-right: 15px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.sidebar-wrapper .sidebar-header .user-info {
  float: left;
}

.sidebar-wrapper .sidebar-header .user-info > span {
  display: block;
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
  font-size: 12px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status {
  font-size: 11px;
  margin-top: 4px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status i {
  font-size: 8px;
  margin-right: 4px;
  color: #5cb85c;
}

/*-----------------------sidebar-search------------------------*/

.sidebar-wrapper .sidebar-search > div {
  padding: 10px 20px;
}

/*----------------------sidebar-menu-------------------------*/

.sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}

.sidebar-wrapper .sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 8px 30px 8px 20px;
}

.sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 10px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}

.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  font-style: normal;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  padding-left: 25px;
  font-size: 13px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-size: 8px;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
  display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(90deg);
  right: 17px;
}

/*--------------------------side-footer------------------------------*/

.sidebar-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
}

.sidebar-footer > a {
  flex-grow: 1;
  text-align: center;
  height: 30px;
  line-height: 30px;
  position: relative;
}

.sidebar-footer > a .notification {
  position: absolute;
  top: 0;
}

.badge-sonar {
  display: inline-block;
  background: #980303;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  position: absolute;
  top: 0;
}

.badge-sonar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #980303;
  opacity: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: sonar 1.5s infinite;
}

/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {

  width: 100%;
  padding-left: 0px;
  padding-top: 20px;

  background: #eff7fa;
}

.page-wrapper .page-content > div {
  padding: 15px 40px 15px 40px;
}

.page-wrapper .page-content {
  overflow-x: hidden;

}

/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #8b74a0;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #8b74a0;
}
::-webkit-scrollbar-thumb:active {
  background: #6c5a7c;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}


/*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
    background: #4d3f64;
}

.chiller-theme .sidebar-wrapper .sidebar-header,
.chiller-theme .sidebar-wrapper .sidebar-search,
.chiller-theme .sidebar-wrapper .sidebar-menu {
    border-top: 1px solid #806592;
}

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand>a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer>a {
    color: #e6eef2;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover>a,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-wrapper .sidebar-brand>a:hover,
.chiller-theme .sidebar-footer>a:hover i {
    color: #bdbdbd;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
    color: #bdbdbd;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
    color: #e6eef2;
}

.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus+span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
    color: #16c7ff;
    text-shadow:0px 0px 10px rgba(22, 199, 255, 0.5);
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li a i,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    background: #806994;
}

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
    color: #c9ace5;
}

.chiller-theme .sidebar-footer {
    background: #806994;
    box-shadow: 0px -1px 5px #282c33;
    border-top: 1px solid #464a52;
}

.chiller-theme .sidebar-footer>a:first-child {
    border-left: none;
}

.chiller-theme .sidebar-footer>a:last-child {
    border-right: none;
}

.colorUser {

  color: #c9ace5;
}

</style>

<script>
import $ from 'jquery'
import {required,sameAs} from "vuelidate/lib/validators";

export default {

  computed: {
    d_id_user() {
      return this.$store.getters.get_user.id;
    },
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }


    },
    is_login() {
      return this.$store.getters.get_loggedIn;
    },
    //droits daccess  
    d_is_chang_pass() {
      return this.$store.getters.get_is_chang_pass;
    },
    d_gst_activ_qr() {
      return this.$store.getters.get_gst_activ_qr;
    },
    d_gst_devis() {
      return this.$store.getters.get_gst_devis;
    },
    d_gst_bl_client() {
      return this.$store.getters.get_gst_bl_client;
    },
    d_gst_bl_client_val() {
      return this.$store.getters.get_gst_bl_client_val;
    },
    d_gst_br_client() {
      return this.$store.getters.get_gst_br_client;
    },
    d_gst_br_client_val() {
      return this.$store.getters.get_gst_br_client_val;
    },
    d_gst_fact() {
      return this.$store.getters.get_gst_fact;
    },
    d_gst_fact_val() {
      return this.$store.getters.get_gst_fact_val;
    },
    //stock
    d_gst_depo() {
      return this.$store.getters.get_gst_depo;
    },
    d_gst_cat() {
      return this.$store.getters.get_gst_cat;
    },
    d_gst_art() {
      return this.$store.getters.get_gst_art;
    },
    d_gst_barcode() {
      return this.$store.getters.get_gst_barcode;
    },
    d_gst_deplac() {
      return this.$store.getters.get_gst_deplac;
    },

    d_gst_entre() {
      return this.$store.getters.get_gst_entre;
    },

    d_gst_sorti() {
      return this.$store.getters.get_gst_sorti;
    },

    //achat :
    d_gst_fournis() {
      return this.$store.getters.get_gst_fournis;
    },
    d_gst_cmd() {
      return this.$store.getters.get_gst_cmd;
    },
    d_gst_livrais() {
      return this.$store.getters.get_gst_livrais;
    },

    d_gst_avoir() {
      return this.$store.getters.get_gst_avoir;
    },

      //tresor :
    d_gst_bnq() {
      return this.$store.getters.get_gst_bnq;
    },
    d_gst_tresor() {
      return this.$store.getters.get_gst_tresor;
    },
    d_gst_depens() {
      return this.$store.getters.get_gst_depens;
    },
    d_gst_paiem_four() {
      return this.$store.getters.get_gst_paiem_four;
    },
    d_gst_paim_client() {
      return this.$store.getters.get_gst_paim_client;
    },
    d_gst_paim_garag() {
      return this.$store.getters.get_gst_paim_garag;
    },
        //charg / decharge :
    d_gst_client_zon() {
      return this.$store.getters.get_gst_client_zon;
    },
    d_gst_vendr() {
      return this.$store.getters.get_gst_vendr;
    },
    d_gst_prim() {
      return this.$store.getters.get_gst_prim;
    },
    d_gst_cam_boit() {
      return this.$store.getters.get_gst_cam_boit;
    },
    d_gst_charg_vent() {
      return this.$store.getters.get_gst_charg_vent;
    },
    d_gst_charg() {
      return this.$store.getters.get_gst_charg;
    },
    
          //RH :
    d_gst_depart_employ() {
      return this.$store.getters.get_gst_depart_employ;
    },
    d_gst_absencerh() {
      return this.$store.getters.get_gst_absencerh;
    },
    d_gst_congesrh() {
      return this.$store.getters.get_gst_congesrh;
    },
    d_gst_feuill_temp() {
      return this.$store.getters.get_gst_feuill_temp;
    },
    d_gst_trait_salaire() {
      return this.$store.getters.get_gst_trait_salaire;
    },
          //POS :
    d_gst_caisse_pos() {
      return this.$store.getters.get_gst_caisse_pos;
    },
    d_gst_art_cat_pos() {
      return this.$store.getters.get_gst_art_cat_pos;
    },
    d_gst_point_vent() {
      return this.$store.getters.get_gst_point_vent;
    },
          //prod :
    d_gst_prod_proces() {
      return this.$store.getters.get_gst_prod_proces;
    },
    d_gst_prod_recep() {
      return this.$store.getters.get_gst_prod_recep;
    },
    d_gst_prod_prod() {
      return this.$store.getters.get_gst_prod_prod;
    },
          //projets :
    d_gst_proj_art() {
      return this.$store.getters.get_gst_proj_art;
    },
    d_gst_proj_cout_prix() {
      return this.$store.getters.get_gst_proj_cout_prix;
    },
        //Parties :
    d_par_vent() {
      return this.$store.getters.get_par_vent;
    },
    d_par_stock() {
      return this.$store.getters.get_par_stock;
    },
    d_par_achat() {
      return this.$store.getters.get_par_achat;
    },
    d_par_financ() {
      return this.$store.getters.get_par_financ;
    },
    d_par_charg() {
      return this.$store.getters.get_par_charg;
    },
    d_par_rapp() {
      return this.$store.getters.get_par_rapp;
    },
    d_par_param() {
      return this.$store.getters.get_par_param;
    },
    d_par_pos() {
      return this.$store.getters.get_par_pos;
    },
    d_par_rh() {
      return this.$store.getters.get_par_rh;
    },
    d_par_prod() {
      return this.$store.getters.get_par_prod;
    },
    d_par_projet() {
      return this.$store.getters.get_par_projet;
    },
  },


  mounted(){



//afficher login:
                    this.$router.push({ 
                        name: 'login'
                    });
  $(".page-wrapper").removeClass("toggled");


    $(".sidebar-dropdown > a").click(function() {
  $(".sidebar-submenu").slideUp(200);
  if (
    $(this)
      .parent()
      .hasClass("active")
  ) {
    $(".sidebar-dropdown").removeClass("active");
    $(this)
      .parent()
      .removeClass("active");
  } else {
    $(".sidebar-dropdown").removeClass("active");
    $(this)
      .next(".sidebar-submenu")
      .slideDown(200);
    $(this)
      .parent()
      .addClass("active");
  }
});




$("#close-sidebar").click(function() {
  $(".page-wrapper").removeClass("toggled");

});
$("#show-sidebar").click(function() {
  $(".page-wrapper").addClass("toggled");
});


    
  },
  data () {
		return {

            itemForm: {
                    id:'',
                    password:'',
                    password_confirmation:''
                    },
            isSubmitted: false,
    }},

    validations: {
            itemForm: {
                password: {
                    required
                },
                password_confirmation: {
                    required,
                    sameAsPassword: sameAs('password')
                }
            }
    },

methods:{

          edit_click_pass() {


                this.$confirm("Voulez-vous vraiment modifier votre mot de passe ??")
                 .then(() => {
                this.itemForm.id=this.d_id_user
                this.itemForm.password=''
                this.itemForm.password_confirmation=''

                this.$root.$emit('bv::show::modal', 'passModal')
                })
                .catch(error=> {
                console.log(error)

                });


      },
  
        handleSubmitpass(){
            this.isSubmitted = true;


                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

            this.isLoading=true;

            this.$http.patch('/auth/userupdatepass/'+this.itemForm.id,{
                    password: this.itemForm.password,
                    password_confirmation: this.itemForm.password_confirmation,
                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data)
                    this.$bvModal.hide('passModal')

                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

                }

        },


showDashBord(){

if (this.$route.path !== '/') this.$router.push({ 
                        name: 'Home'
                    })


                   
},

}



}




</script>