import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


import VueSimpleAlert from "vue-simple-alert";
import FlashMessage from '@smartweb/vue-flash-message';
import Vuelidate from 'vuelidate'

import '@fortawesome/fontawesome-free/css/fontawesome.min.css'
import '@fortawesome/fontawesome-free/js/fontawesome.min.js'

import '@fortawesome/fontawesome-free/css/solid.min.css'
import '@fortawesome/fontawesome-free/js/solid.min.js'

import VModal from 'vue-js-modal'
import { BootstrapVue} from 'bootstrap-vue'
import VueBarcodeScanner from 'vue-barcode-scanner'

import axios from 'axios'

//axios.defaults.baseURL = 'http://www.api.comanam.ma/api'
//axios.defaults.baseURL = 'http://127.0.0.1:8000/api'
axios.defaults.baseURL = 'http://41.77.113.20:8090/api'



axios.interceptors.request.use (
  function (config) {
    const token = store.getters.get_token;
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject (error);
  }
);


let options = {
  sensitivity: 300, // default is 100
 // requiredAttr: true, // default is false
  controlSequenceKeys: ['NumLock', 'Clear'], // default is null
  callbackAfterTimeout: true // default is false
}

Vue.use(VueBarcodeScanner, options)

Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.use(VueSimpleAlert);
Vue.use(FlashMessage);
Vue.use(Vuelidate)
Vue.use(VModal)
// Install BootstrapVue
Vue.use(BootstrapVue)
//Vue.use(IconsPlugin)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
